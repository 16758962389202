import { createRouter, createWebHistory } from 'vue-router';
import Guard from '@/services/middleware';

const routes = [
    {
        path: '/',
        redirect: '/login'
    },

    {
        path: '/home',
        name: 'homePage',
        component: () => import('@/views/HomePage.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/LoginPage.vue')
    },
    {
        path: '/forget-password',
        name: 'forgetPassword',
        component: () => import('@/views/ForgetPasswordPage.vue'),
    },
    {
        path: '/create-account',
        name: 'createAccount',
        component: () => import('@/views/CreateAccountPage.vue'),
    },

    {
        path: '/agenda',
        name: 'agenda',
        component: () => import('@/views/AgendaPage.vue'),
        beforeEnter: Guard.authDoctor
    },
    {
        path: '/appointments',
        name: 'appointments',
        component: () => import('@/views/AppointmentsPage.vue'),
        props: route => ({ objStr: route.query.objStr }),
        beforeEnter: Guard.auth
    },
    {
        path: '/medical-records-list',
        name: 'medicalRecordsList',
        component: () => import('@/views/MedicalRecordsListPage.vue'),
        beforeEnter: Guard.authDoctor
    },
    {
        path: '/medical-record',
        name: 'medicalRecord',
        component: () => import('@/views/MedicalRecordPage.vue'),
        props: route => ({ objStr: route.query.objStr }),
        beforeEnter: Guard.authDoctor
    },


    // {
    //     path: '/accrediteds-upload',
    //     name: 'accreditedsUpload',
    //     component: () => import('@/views/AccreditedsUploadPage.vue'),
    //     beforeEnter: Guard.authAdm
    // },
    {
        path: '/contacts',
        name: 'contacts',
        component: () => import('@/views/ContactsPage.vue'),
        meta: { requiredPermissions: 'Contatos' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/contacts-add',
        name: 'contactsAdd',
        component: () => import('@/views/ContactsAddPage.vue'),
        meta: { requiredPermissions: 'Contatos' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/contacts-visualize',
        name: 'contactsVisualize',
        component: () => import('@/views/ContactsVisualizePage.vue'),
        props: route => ({ rId: route.query.rId }),
        meta: { requiredPermissions: 'Contatos' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/contacts-update',
        name: 'contactsUpdate',
        component: () => import('@/views/ContactsUpdatePage.vue'),
        props: route => ({ rId: route.query.rId }),
        meta: { requiredPermissions: 'Contatos' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: () => import('@/views/NotificationsPage.vue'),
        meta: { requiredPermissions: 'Notificacoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/notifications-add',
        name: 'notificationsAdd',
        component: () => import('@/views/NotificationsAddPage.vue'),
        meta: { requiredPermissions: 'Notificacoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/notifications-visualize',
        name: 'notificationsVisualize',
        component: () => import('@/views/NotificationsVisualizePage.vue'),
        props: route => ({ rId: route.query.rId }),
        meta: { requiredPermissions: 'Notificacoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/banners',
        name: 'banners',
        component: () => import('@/views/BannersPage.vue'),
        meta: { requiredPermissions: 'Banners' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/banners-add',
        name: 'bannersAdd',
        component: () => import('@/views/BannersAddPage.vue'),
        meta: { requiredPermissions: 'Banners' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/banners-visualize',
        name: 'bannersVisualize',
        component: () => import('@/views/BannersVisualizePage.vue'),
        props: route => ({ rId: route.query.rId }),
        meta: { requiredPermissions: 'Banners' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/documents',
        name: 'documents',
        component: () => import('@/views/DocumentsPage.vue'),
        meta: { requiredPermissions: 'Documentos' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/documents-add',
        name: 'documentsAdd',
        component: () => import('@/views/DocumentsAddPage.vue'),
        meta: { requiredPermissions: 'Documentos' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/documents-visualize',
        name: 'documentsVisualize',
        component: () => import('@/views/DocumentsVisualizePage.vue'),
        props: route => ({ rId: route.query.rId }),
        meta: { requiredPermissions: 'Documentos' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('@/views/NewsPage.vue'),
        meta: { requiredPermissions: 'Noticias' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/news-add',
        name: 'newsAdd',
        component: () => import('@/views/NewsAddPage.vue'),
        meta: { requiredPermissions: 'Noticias' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/news-update',
        name: 'newsUpdate',
        component: () => import('@/views/NewsUpdatePage.vue'),
        props: route => ({ rId: route.query.rId }),
        meta: { requiredPermissions: 'Noticias' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/news-visualize',
        name: 'newsVisualize',
        component: () => import('@/views/NewsVisualizePage.vue'),
        props: route => ({ rId: route.query.rId }),
        meta: { requiredPermissions: 'Noticias' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/user-update',
        name: 'userUpdate',
        component: () => import('@/views/UserUpdatePage.vue'),
        props: route => ({ mps: route.query.mps }),
        meta: { requiredPermissions: 'Configuracoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/config',
        name: 'config',
        component: () => import('@/views/ConfigsPage.vue'),
        meta: { requiredPermissions: 'Configuracoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/profissional',
        name: 'profissional',
        component: () => import('@/views/ProfissionalConfigPage.vue'),
        meta: { requiredPermissions: 'Configuracoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/integration',
        name: 'integration',
        component: () => import('@/views/IntegrationPage.vue'),
        meta: { requiredPermissions: 'Configuracoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/app-config',
        name: 'appConfig',
        component: () => import('@/views/AppModulesPage.vue'),
        meta: { requiredPermissions: 'Configuracoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/reception',
        name: 'reception',
        component: () => import('@/views/RecepcaoPage.vue'),
        meta: { requiredPermissions: 'Configuracoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/info',
        name: 'info',
        component: () => import('@/views/InfoPage.vue'),
        props: route => ({ rId: route.query.rId }),
        meta: { requiredPermissions: 'Informacoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/calls-info',
        name: 'callsInfo',
        component: () => import('@/views/CallsInfoPage.vue'),
        props: route => ({ rId: route.query.rId }),
        meta: { requiredPermissions: 'Informacoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/ia-info',
        name: 'iaInfo',
        component: () => import('@/views/IaInfoPage.vue'),
        props: route => ({ rId: route.query.rId }),
        meta: { requiredPermissions: 'Informacoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/schendules-config',
        name: 'schendulesConfig',
        component: () => import('@/views/AgendaConfigPage.vue'),
        meta: { requiredPermissions: 'Configuracoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/ia-config',
        name: 'iaConfig',
        component: () => import('@/views/IAConfigPage.vue'),
        meta: { requiredPermissions: 'Configuracoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/auth-config',
        name: 'authConfig',
        component: () => import('@/views/AuthConfigPage.vue'),
        meta: { requiredPermissions: 'Configuracoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/demonstrative-config',
        name: 'demonstrativeConfig',
        component: () => import('@/views/DemonstrativeConfigPage.vue'),
        meta: { requiredPermissions: 'Configuracoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/usuarios-grupos',
        name: 'usersGroup',
        component: () => import('@/views/AccessConfigPage.vue'),
        meta: { requiredPermissions: 'Configuracoes' },
        beforeEnter: Guard.authAdm
    },
    {
        path: '/statistics',
        name: 'statitistics',
        component: () => import('@/views/StatisticsPage.vue'),
        meta: { requiredPermissions: 'Informacoes' },
        beforeEnter: Guard.authAdm
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
