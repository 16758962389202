import utils from '@/helpers/utils';
import store from "@/store";

export default {
    async authDoctor(to, from, next) {
        await store.dispatch('toggleProgressBar', 1);

        const isLoggedIn = await store.state.isLoggedIn;
        const userData = !utils.isEmpty(store.state.userData) ? JSON.parse(store.state.userData) : [];

        // verifies if the token is created
        if (!isLoggedIn || utils.isEmpty(userData) || utils.isEmpty(userData.sessionID) || !utils.isEmpty(userData.isSysAdm)) {
            await store.dispatch('setIsLoggedOff');
            utils.removeAppCookies();
            next('/login');
            await store.dispatch('toggleProgressBar', 0);
            return;
        }

        next();
        await store.dispatch('toggleProgressBar', 0);
    },
    async auth(to, from, next) {
        await store.dispatch('toggleProgressBar', 1);

        const isLoggedIn = await store.state.isLoggedIn;
        const userData = !utils.isEmpty(store.state.userData) ? JSON.parse(store.state.userData) : [];
        utils.l(userData);

        // verifies if the token is created
        if (!isLoggedIn || utils.isEmpty(userData) || utils.isEmpty(userData.sessionID)) {
            await store.dispatch('setIsLoggedOff');
            utils.removeAppCookies();
            next('/login');
            await store.dispatch('toggleProgressBar', 0);
            return;
        }

        next();
        await store.dispatch('toggleProgressBar', 0);
    },
    async authAdm(to, from, next) {
        await store.dispatch('toggleProgressBar', 1);

        const isLoggedIn = await store.state.isLoggedIn;
        const userData = !utils.isEmpty(store.state.userData) ? JSON.parse(store.state.userData) : [];

        const requiredPermissions = to.meta.requiredPermissions;

        let hasPermission = true;

        if (!utils.isEmpty(requiredPermissions)) {
            const userAccesses = userData.Permissoes.Acessos;

            Object.entries(userAccesses).forEach(([key, value]) => {
                if (key == requiredPermissions) {
                    if (value == true) {
                        hasPermission = true;
                    } else {
                        hasPermission = false;
                    }
                }
            });
        }


        // verifies if the token is created
        if (!isLoggedIn || utils.isEmpty(userData) || utils.isEmpty(userData.sessionID) || utils.isEmpty(userData.isSysAdm) || !hasPermission) {
            await store.dispatch('setIsLoggedOff');
            utils.removeAppCookies();
            next('/login');
            await store.dispatch('toggleProgressBar', 0);
            return;
        }

        next();
        await store.dispatch('toggleProgressBar', 0);
    },
}
