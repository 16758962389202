import { createStore } from 'vuex'
import mutations from './mutations';
import actions from './actions';
import Cookie from 'js-cookie';

export default createStore({
    state: {
        isLoggedIn: (Cookie.get(process.env.VUE_APP_IS_LOGGED_IN) ? true : false),
        isProgressBarVisible: false,

        userData: Cookie.get(process.env.VUE_APP_USER_DATA)
    },
    getters: {},
    mutations,
    actions,
    modules: {}
})

