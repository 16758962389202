import utils from '@/helpers/utils';

export default {
    setIsLoggedIn(state) {
        state.isLoggedIn = (
            utils.hasCookieByName(String(process.env.VUE_APP_IS_LOGGED_IN))
        );
    },

    setIsLoggedOff(state) { state.isLoggedIn = 0; },

    toggleProgressBar(state, status) { state.isProgressBarVisible = status; },

    setUserData(state, userData) { state.userData = userData; }
}
