import {createApp} from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import utils from './helpers/utils';

/* Main dependencies */
import VueSweetalert2 from 'vue-sweetalert2';
import mitt from "mitt";

/* Theme variables */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import './theme/theme.css';
import './theme/utils.css';

if(!utils.useDebug) { console.clear(); }
const emitter = mitt();

const app = createApp(App)
    .use(router)
    .use(store)
    .use(VueSweetalert2);
app.config.globalProperties.emitter = emitter;

router.isReady().then(() => {
    app.mount('#app');
});
