export default {
    // access controll
    setIsLoggedIn({ commit }) { commit('setIsLoggedIn'); },
    setIsLoggedOff({ commit }) { commit('setIsLoggedOff'); },

    // toggles
    toggleProgressBar({ commit }, isActive) { commit('toggleProgressBar', isActive); },

    // user
    setUserData({ commit }, userData) { commit('setUserData', userData); }
}
